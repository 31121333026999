import React from "react";
import { FormTable, Text, Button, Input } from "components";
import { Select, Row, Col } from "antd";
import { useIntl, FormattedMessage } from "react-intl";

/**
 * 관리자 > 라이선스 관리 > Top 검색
 */

const { Option } = Select;

const AdminLicenseTop = ({
  onChangeValues,
  inputValues,
  fetchLicenseList,
  fetchLicenseCount,
  crInfo,
  userTypeList,
  userStatusList,
  onClickReset,
  onChangeSelectCr,
  onChangeUserType,
  onChangeStatus,
  onClickSearch
}) => {
  const { formatMessage } = useIntl();
  const selectPlaceholder = formatMessage({ id: "T0024" }); // 선택
  const searchPlaceholder = formatMessage({ id: "T0025" }); // 검색어를 입력하세요

  // const onClickSearch = () => {
  //   let selectUserType;
  //   let selectUserStatus;
  //   if (inputValues.selectUserType.length === 0) {
  //     selectUserType = [...userTypeList];
  //   } else {
  //     selectUserType = [...inputValues.selectUserType];
  //   }
  //   if (inputValues.selectUserStatus.length === 0) {
  //     selectUserStatus = [...userStatusList];
  //   } else {
  //     selectUserStatus = [...inputValues.selectUserStatus];
  //   }
  //   fetchLicenseList({ ...inputValues, selectUserType, selectUserStatus });
  //   fetchLicenseCount(inputValues.selectCr.crXEnv);
  // };

  // 업무 ID나 업무명 인풋창에서 엔터 누르면 검색
  const onKeyPress = (e) => {
    if (e.key === "Enter") {
      onClickSearch(); // Enter 입력이 되면 클릭 이벤트 실행
    }
  };

  return (
    <div className="search__container">
      <FormTable pointBorder={false} size="sm">
        <colgroup>
          <col width="14%" />
          <col width="19.333%" />
          <col width="14%" />
          <col width="19.333%" />
          <col width="14%" />
          <col width="19.333%" />
        </colgroup>
        <tbody>
          <tr>
            <th style={{ borderRadius: "14px 0 0 0" }}>
              <Text>
                <FormattedMessage id="T1036" />
                {/* CR */}
              </Text>
            </th>
            <td>
              <Select
                className="license__search_select"
                value={inputValues.selectCr.crCd}
                onChange={onChangeSelectCr}
                style={{ width: 200 }}
              >
                {crInfo.map((v) => (
                  <Option key={v.crCd} value={v.crCd}>
                    {v.crDesc}
                  </Option>
                ))}
              </Select>
            </td>
            <th>
              <Text>User Type</Text>
            </th>
            <td>
              <Select
                mode="multiple"
                maxTagCount="responsive"
                placeholder={selectPlaceholder} // 선택
                value={inputValues.selectUserType}
                className="license__search_select"
                onChange={onChangeUserType}
                style={{ width: 200 }}
              >
                {userTypeList.map((v) => (
                  <Option key={v} value={v}>
                    {v}
                  </Option>
                ))}
              </Select>
            </td>
            <th>
              <Text>User Status</Text>
            </th>
            <td>
              <Select
                mode="multiple"
                maxTagCount="responsive"
                value={inputValues.selectUserStatus}
                placeholder={selectPlaceholder} // 선택
                className="license__search_select"
                onChange={onChangeStatus}
                style={{ width: 200 }}
              >
                {userStatusList.map((v) => (
                  <Option key={v} value={v}>
                    {v}
                  </Option>
                ))}
              </Select>
            </td>
          </tr>
          <tr>
            <th>
              <Text>User Name</Text>
            </th>
            <td>
              <Input
                placeholder={searchPlaceholder} //검색어를 입력하세요
                value={inputValues.inputUserName}
                onChange={onChangeValues}
                style={{
                  width: 200,
                  height: 32,
                }}
                name="inputUserName"
                onKeyPress={onKeyPress}
              />
            </td>
            <th>
              <Text>First Name</Text>
            </th>
            <td>
              <Input
                placeholder={searchPlaceholder} //검색어를 입력하세요
                value={inputValues.inputFirstName}
                onChange={onChangeValues}
                style={{
                  width: 200,
                  height: 32,
                }}
                name="inputFirstName"
                onKeyPress={onKeyPress}
              />
            </td>
            <th>
              <Text>Email</Text>
            </th>
            <td>
              <Input
                placeholder={searchPlaceholder} //검색어를 입력하세요
                value={inputValues.inputSearchEmail}
                onChange={onChangeValues}
                style={{
                  width: 200,
                  height: 32,
                }}
                name="inputSearchEmail"
                onKeyPress={onKeyPress}
              />
            </td>
          </tr>
        </tbody>
      </FormTable>
      <Row style={{ padding: "0 13px" }}>
        <Col
          span={12}
          style={{
            padding: 10,
            display: "flex",
          }}
        ></Col>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "0 25px",
            padding: "10px 0",
          }}
        >
          <Button width="120" height="40" onClick={onClickReset}>
            <FormattedMessage id="T0037" />
            {/* 초기화 */}
          </Button>
          <Button
            width="120"
            height="40"
            type="primary"
            onClick={onClickSearch}
          >
            <FormattedMessage id="T0039" />
            {/* 검색 */}
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default AdminLicenseTop;
