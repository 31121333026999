import { useEffect, useState } from 'react';
import { Button, Table, Text } from "components";
import { useIntl } from "react-intl";

const AdminLicenseBottom = ({
  licenseCount,
  onClickDownloadExcel,
  columns,
  licenseList,
  listLoading,
  page, setPage
}) => {
  const { formatMessage } = useIntl();

  const onChangeTable = (page) => {
    setPage(page.current);
  };

  return (
    <div className="license__bottom">
      <div className="license__info_box">
        <Text className="license__info_text">
          {formatMessage({ id: "T0621" })} - Total :&nbsp;
          {licenseCount.totalCount} / Avaliable :&nbsp;
          {licenseCount.availableCount} / Used : {licenseCount.usedCount}
          &nbsp;
          {/*Bot Creator License 현황 */}
        </Text>
        <Button onClick={onClickDownloadExcel}>
          {formatMessage({ id: "T0041" })}
          {/* 엑셀 다운로드 */}
        </Button>
      </div>
      <Table
        rowKey={(item, index) => index}
        columns={columns}
        dataSource={licenseList}
        loading={listLoading}
        pagination={true}
        pageOptions={{
          total: licenseList.length,
          current: page,
        }}
        onChange={onChangeTable}
        scroll={{x: true}}
      />
    </div>
  );
};

export default AdminLicenseBottom;
